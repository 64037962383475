<template>
  <div>
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class="border-radius-6"
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/operate/operateData"
          @click.native="handleTabClick('/statistics/operate/operateData')"
          >运营数据</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/operate/channelData"
          @click.native="handleTabClick('/statistics/operate/channelData')"
          >每日公众号数据</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/operate/assess"
          @click.native="handleTabClick('/statistics/operate/assess')"
          >运营考核</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/operate/operateStatic"
          @click.native="handleTabClick('/statistics/operate/operateStatic')"
          >运营统计</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/operate/intervalOperate"
          @click.native="handleTabClick('/statistics/operate/intervalOperate')"
          >运营区间</el-menu-item
        >
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "channel",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
